@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat'), url("../../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat'), url("../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"); }

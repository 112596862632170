

@import "../../styles/_helpers.sass";


.network {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8rem 1rem 1rem 2rem;
    margin-left: 5vw;
    margin-right: 5vw;

    &__sections-testnet {

        margin-bottom: 2rem;
        @media (max-width: 692px) {
            max-width: 100%; } }


    &__central {
        display: flex;
        justify-content: center;
        margin: 2rem 0; }

    &__join-hero {
        @include font(Montserrat, 300, 50px, $white);
        margin-bottom: 40px;
        white-space: nowrap;


        @media (max-width: 2048px) {
            transition: ease-in-out 1s;
            font-size: 42px;

            margin: 2rem 0;
            white-space: nowrap; }


        @media (max-width: 1280px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }



        @media (max-width: 900px) {
            transition: ease-in-out 1s;
            font-size: 42px;
            white-space: nowrap; }




        @media (max-width: 768px) {
            font-size: 2.5rem;
            max-width: 90%;
            white-space: normal; }


        &-subtitle {

            margin: 2rem 0;

            @media (max-width: 768px) {
                font-size: 1.45rem;
                max-width: 90%; } } } }

.node {
    &__button {
        width: 20rem;
        height: 5rem;
        font-size: 2.5rem;
        border: 2px solid $white;
        border-radius: 12px;
        margin: 2rem 0;
        background-color: black;
        color: $white; }
    &__reward {
        display: flex;
        margin: 2rem 0; }
    &__prop-mobile {
        margin: 1rem 2rem 0rem 0rem; } }

.items {
    &_center {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        height: 100%;


        div {
            width: calc(33.33% - 20px); // subtract any margin or padding you have
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 1rem; } } }

.welcome__section-title-invite {
    font-size: 1.3rem;
    margin-top: 2rem;
    align-self: center;
    max-width: 355px;
    font-weight: 600; }

.welcome__section-invite {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: 0 3rem 3rem 3rem;
    @media (max-width: 692px) {
      margin: 0; } }

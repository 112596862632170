@import "../../../styles/_helpers.sass";

.body_meet {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin-top: -2rem;

  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: $white;



  @include small-phone-screen {
    width: 90vw; } }



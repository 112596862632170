@import "../../styles/helpers";

.privacy {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-relayz {
    margin-top: -2rem; }

  &-relayz, &-token {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
    @media(max-width:  1000px) {
      min-height: 50vh; }

    @include phone-screen {
      min-height: auto; }

    &-static {
      width: 85vw;
      max-width: 1400px;
      margin-top: 100px;
      margin-right: 20px; } }

  &-token {
    &-static {
      margin-top: -45px;
      max-width: 1800px;
      margin-bottom: -100px; } }

  &-benefits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 65px 40px 0;
    @media (max-width:  500px) {
      padding: 65px 15px 0; }

    &-title {
      max-width: 90%;
      @include font(Montserrat, 600, 36px, $white);
      line-height: 43px;
      margin-bottom: 25px;
      @media (max-width:  500px) {
        font-size: 28px;
        line-height: 36px; } } }

  &-benefit {
    @include flex-props-center;
    margin-bottom: 40px;

    &-image {
      width: 80px;
      height: auto;
      margin-right: 20px; }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }

    &-title {
      @include font(Montserrat, 400, 24px, $primaryPurple);
      margin-bottom: 5px; }

    &-text {
      @include font(Montserrat, 400, 18px, $white); } } }

.roadmap {
  margin-top: 100px;

  &__container {
    @include font(Montserrat, 400, 0.875rem, $white);
    //width: 80vw
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -5rem;
    @include phone-screen {
      margin-bottom: 0; }

    &--element {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }

  &__subtitle {
    padding: 2vw 0;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 3rem;
    max-width: 1200px;
    gap: 1rem;
    padding-bottom: 80px;

    @media ( min-width: 1200px) {
      margin-left: 5vw; }

    @include phone-screen {
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin-left: 0;
      align-items: center;
      @media (max-width: 580px) {
        margin-top: 0; } }

    &--item {
      padding: 2rem 0;
      &--container {
        max-width: 16vw;
        display: flex;
        flex-direction: column;
        min-width: 5vw;
        @include phone-screen {
          min-width: 60vw; } } } }

  &__flex {
    margin-right: 1rem;
    @include phone-screen {
      display: flex;
      text-align: right;
      margin-right: 0; } }

  &__list {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 5vw;

    &--content {

      padding: 1rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center; }

    &--title {

      padding: 1rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primaryPurple;

      &--text {

        padding-bottom: 1rem; }

      &-right {

        color: $white;
        min-width: 20rem;
        max-width: 50rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 5px solid B399FF; } }

    &--item {

      &-right {

        color: $white;
        min-width: 10rem;

        width: 30vw;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;

        border-right: 5px solid $primaryPurple;
        margin-bottom: -3rem;
        padding: 5rem 2rem 2rem 8rem; }

      &-left {

        color: $white;
        min-width: 10rem;

        width: 30vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -5px;
        border-left: 5px solid $primaryPurple;
        padding: 0rem 0rem 8rem 2rem; } } } }

.roadmap__graph {
  //height: 40vw
  display: block;
  margin: 0 0 -5vw 0;
  object-fit: cover;
  object-position: center;
  @media (max-width: 580px) {
    display: none; } }

.privacy__box {
  width: 80vw;
  height: 40vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  @media (min-width: 580px) {
    width: 90vw;
    height: 65vh;
    padding: 2rem; } }

.privacy__box-text-spacing {
  margin-bottom: 1rem; }


$secondColor: #1A1A1A;

$white: #ffffff;
$black: #000000;

$bgDark: #221F26;
$darkGray: rgba(47, 47, 47, 0.65);
$sectionBg: #1A1A1A;
$bgGray: #9f9f9f54;
$borderGray: #afafaf84;
$mediumGray: rgba(255, 255, 255, 0.6);
$lightGray: rgba(255,255,255,0.4);
$lighterGray: rgba(255,255,255,0.2);
$navigationBg: rgba(70, 70, 70, 0.9);
$subNavBg: rgba(54, 54, 54, 0.9);
$darkGrayDetails: #272727;
$modalBg: rgba(0, 0, 0, 0.8);

$darkBgPurple: rgba(102, 69, 142, 0.2);
$ctaBgDarkPurple: rgba(102, 69, 142, 0.3);
$bgPurple: #b399ffa9;
$bgDeepPurple: #b399ff5e;
$darkPurple: #66458E;
$primaryPurple: #B399FF;

$darkGreen: #90ff9054;
$green: #8ef38e;

$darkYellow: #ffd70040;
$yellow: #FFB432;

$darkRed: #ff909054;
$red: #e49c9c;

$gold: #FFD700;
$silver: #C0C0C0;
$bronze: #CD7F32;

@import "../../styles/helpers";

.header__submenu {
  @include font(Montserrat, 400, 1rem, $white);
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 65px;
  z-index: 10;
  @include menu-size__max {
    right: 5rem; }
  @media (max-width: 360px) {
    right: 3.5rem; }

  &--noauthenticated {
    @include menu-size__max {
      display: none; } }

  &-item {
    @include font(Montserrat, 400, 1rem, $white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-right: 1rem;
    min-width: fit-content;
    background: transparent;
    @include menu-size__max {
      margin-right: 0; }
    &:hover {
      img {
        background: $darkPurple; } }
    &--selected {
      img {
        background: $darkPurple; } }
    &-icon {
      width: 32px;
      height: 32px;
      border: 2.5px solid $primaryPurple;
      border-radius: 15px;
      padding: 0.2rem 0.3rem; }
    &-last {
      @include font(Montserrat, 400, 1rem, $white);
      @include menu-size__max {
        display: none; }
      &--selected {
        border-color: $primaryPurple;
        background-color: $bgPurple; } } } }

.header__float-menu {
  position: absolute;
  top: 65px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @include font(Montserrat, 300, 18px, $primaryPurple);
  padding: 3rem 1rem;
  gap: 2rem;
  z-index: 10;
  background: $navigationBg;
  backdrop-filter: blur(10px);
  height: fit-content;
  width: 350px;
  overflow: hidden;
  @include menu-size__max {
    width: 100%; }
  &-item {
    text-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    svg {
      fill: $white; }
    &:hover {
      color: $primaryPurple !important;
      svg {
        fill: $primaryPurple; } }
    &-signout {
      margin-top: 2rem;
      background: transparent;
      @include font(Montserrat, 300, 17px, $primaryPurple);
      svg {
        fill: $primaryPurple; } } } }

.header__submenu-overlay {
  z-index: 9;
  position: absolute;
  inset: 0;
  bottom: -100vh;
  background: transparent; }

.coming-soon {
  color: $borderGray; }

@import "../../styles/helpers";

.footer {
  width: 100%;
  padding: 0px 110px 0px;
  max-width: 1000px;
  border-top: 1px solid $primaryPurple;
  margin: 5rem 0 2rem;
  @include flex-props-space-evenly;
  @media (max-width: 1000px) {
    padding: 0 0 15px; }



  @include phone-screen {
    flex-wrap: wrap;
 }    //justify-content: space-between




  &-hr {
    width: 90%;
    margin: 0 auto 20px;
    height: 1px;
    //background-color: $primaryPurple
    border: none; }

  &-logo {
    width: 20px;
    margin-right: 20px;
    @include phone-screen {
      display: none; } }

  &-link {
    min-width: 6rem;
    text-align: center;
    @include font(Montserrat, 400, 16px, $white);

    @include phone-screen {
      font-size: 16px;
      font-weight: 400;
      color: $primaryPurple; }

    &-icon {
      margin-right: 10px; }

    &:visited {
      color: $white;
      @include phone-screen {
        color: $primaryPurple; } }

    &-wrapper {

      @include flex-props-center;

      @include phone-screen {

        margin-bottom: 10px;
        //width: 100%
        justify-content: center;

        &-email {
          display: flex;
          align-items: center; } } } } }

.footer-link {
  font-weight: 300;
  color: $white;

  &-text {
    min-width: 8rem;
    text-align: center;
    @include font(Montserrat, 400, 16px, $white);
    @include phone-screen {
      margin-top: 5px;
      //margin-right: 20px
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      color: $primaryPurple; } } }




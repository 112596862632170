@import "../../styles/helpers.sass";

.relayz-card {
    &-wrapper {
        margin: 1rem;
        margin-top: -0.5rem; }
    p {
        font-size: 1.25rem; }
    li {
        font-size: 1rem; } }
.relayz-card-titles {
  display: flex;
  justify-content: center;
  margin: 1rem;
  gap: 2rem;


  h4 {
    cursor: pointer;
    color: $bgPurple;
    transition: color 0.3s;
    padding: 0.25rem;

    &.active {
      color: $primaryPurple;
      border-bottom: 1px solid $primaryPurple; } }


  h2 {
    cursor: pointer;
    color: $bgPurple;
    transition: color 0.3s;
    padding: 0.25rem;

    &.active {
      color: $primaryPurple;
      border-bottom: 1px solid $primaryPurple; } } }


.reward_tab-list-title {
    margin: 0 0 1rem 0; }

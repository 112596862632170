@import "../../styles/helpers";

.welcome {

  &__login-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem; }

  &__container {
    width: 90vw;
    max-width: 900px;
    margin: 8rem auto 4rem;
    @include font(Montserrat, 400, 1.2rem, $white);
    @include small-phone-screen; }
  &__thank-you {
    width: 90vw;

    max-width: 900px;
    margin: 8rem auto 4rem;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    &-title {
      margin-bottom: 0.5rem;
      align-self: start; } }
  &__thank-you-invite {
    width: 90vw;
    height: 60vh;
    max-width: 900px;
    margin: 0rem auto 4rem;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    &-title {
      margin-bottom: 0.5rem;
      align-self: start; } }

  &__title {
    font-size: 2.5rem;
    margin-bottom: 4rem;
    text-align: center;
    @include small-phone-screen {
      text-align: initial;
      margin-bottom: 2rem; } }

  &__subtitle {
    @include font(Montserrat, 400, 1.5rem, $white);
    margin-bottom: 2rem;
    span {
      @include font(Montserrat, 400, 1.5rem, $white); } }

  &__section {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include phone-screen {
      flex-direction: column;
      gap: 2rem; }
    &-title {
      font-size: 1.3rem;
      margin-top: 1rem;
      align-self: center; }

    &-input-container {
      display: flex;
      align-items: center;
      width: fit-content;
      border-radius: 10px;
      background-color: $bgDark;
      height: 2.9rem;
      img {
        width: 1.25rem;
        height: auto;
        margin-inline: 1rem; }
      input {
        background-color: $bgDark;
        height: 100%;
        max-width: 200px;
        width: 100%;
        border-radius: 0 10px 10px 0;
        color: $white;
        font-size: 1rem;
        padding-right: 1rem;
        &::placeholder {
          color: $mediumGray; } }
      &-referral {
        display: flex;
        align-items: center;
        width: fit-content;
        border-radius: 10px;
        background-color: $bgDark;
        min-width: 275px;
        min-height: 3rem;

        img {
          width: 1.25rem;
          height: auto;
          margin-inline: 1rem; }
        input {
          background-color: $bgDark;
          height: 100%;
          max-width: 200px;
          width: 100%;
          border-radius: 0 10px 10px 0;
          color: $white;
          font-size: 1rem;
          &::placeholder {
            color: $mediumGray; } } } }

    &-button {
      background-color: transparent;
      max-width: 220px;
      margin-top: 1rem;
      display: flex;
      justify-content: center; }

    &-image--metamask {
      width: 50%;
      min-width: 200px; }
    &-image--near {
      width: 50%;
      min-width: 120px; }
    &-message {
      @include font(Montserrat, 400, 1.5rem, $white);
      text-align: center;
      &:nth-child(1) {
        margin-top: 4rem; } }
    &-purpose {
      @include font(Montserrat, 400, 1rem, $white);
      max-width: 355px;
      &-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: 24rem; }

      &-item {
        font-size: 1rem;
        cursor: pointer;
        &:last-child {
          margin-block: 1rem;
          span {
            font-family: "Montserrat"; } } }
      &-text {
        text-align: left;
        &-last-child {
          color: $mediumGray; } } }

    &-steps {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // background-color: $darkBgPurple
      padding-inline: 0.5rem;
      &-buttons {
        max-width: 250px;
        position: relative;
        align-self: center;
        button {
          width: 140px;
          bottom: 1rem;
          outline: 0;
          transition: filter 0.2s ease-in-out;
          padding: 0.5rem 1.2rem;
          border-radius: 30px;
          border: solid 2px $primaryPurple;
          @include font(Montserrat, 400, 1rem, $white);
          background: none;
          &:hover {
            color: $white;
            background-color: $primaryPurple; }
          &:disabled {
            opacity: 0.3;
            &:hover {
              cursor: not-allowed;
              opacity: 0.3;
              color: $black; } } } }
      &-previous {
        right: 1rem; }
      &-next {
        left: 1rem; } }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      flex: 1;
      min-width: 300px;
      justify-content: flex-start;
      // max-width: 380px
      &:nth-child(1) {
        max-width: 380px; }
      @include phone-screen {
        &:nth-child(2) {
          align-items: flex-start; }
        &:nth-child(1) {
          max-width: none; } }
      @include smaller-phone-screen {
        min-width: 250px; }
      &-button-wrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        justify-self: center; } }

    &-error-log {
      font-size: 0.9rem;
      color: $red; } } }

.input-error {
  outline: 1px solid $gold; }
.input-success {
  outline: 1px solid $primaryPurple; }
.button-inactive {
  outline: 1px solid $primaryPurple; }
.near-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  &-success {
    margin-top: -0.5rem;
    font-size: 1rem; } }

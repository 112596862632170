@import "../../../styles/_helpers.sass";


.network {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5vw;
    margin-right: 5vw;

    &__sections-testnet {

        margin-bottom: 2rem;
        @media (max-width: 692px) {
            max-width: 100%; } }


    &__central {
        display: flex;
        justify-content: center;
        margin: 2rem 0; }

    &__join-hero {
        @include font(Montserrat, 300, 50px, $white);
        margin-bottom: 40px;
        white-space: nowrap;


        @media (max-width: 2048px) {
            transition: ease-in-out 1s;
            font-size: 42px;

            margin: 2rem 0;
            white-space: nowrap; }


        @media (max-width: 1280px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }



        @media (max-width: 900px) {
            transition: ease-in-out 1s;
            font-size: 42px;
            white-space: nowrap; }




        @media (max-width: 768px) {
            font-size: 2.5rem;
            max-width: 90%;
            white-space: normal !important; }


        &-subtitle {

            margin: 2rem 0;

            @media (max-width: 768px) {
                font-size: 1.75rem;
                max-width: 90%; } } } }

.node {
    &__button {
        width: 20rem;
        height: 5rem;
        font-size: 2.5rem;
        border: 2px solid $white;
        border-radius: 12px;
        margin: 2rem 0;
        background-color: black;
        color: $white; }
    &__reward {
        display: flex;
        margin: 2rem 0; }
    &__prop-mobile {
        margin: 0rem 2rem 0rem 0rem; } }

.items {
    &_center {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        @include phone-screen {
            display: flex !important;
            flex-direction: column !important; }


        div {
            width: calc(33.33% - 20px); // subtract any margin or padding you have
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 1rem;

            @include phone-screen {
                width: 100% !important;
                margin-bottom: 2rem; } } } }



.text__larger {
    font-size: 1.5rem;
    font-weight: 600; }

.horizontal {
    @media (min-width: 900px) {
        display: flex; } }

.column {

    &-left {
        max-width: 50%;
        margin-right: 2rem;
        @media (max-width: 900px) {
            max-width: 100%;
            margin: 0; } }



    &-right {
        max-width: 50%;
        margin-left: 2rem;
        @media (max-width: 900px) {
            margin: 2.5rem 0 0 0;
            max-width: 100%; } } }

.ilustration__small {
    max-height: 30vw;
    @media (min-width: 700px) {
        max-height: 150px; } }


.center_logo {
    justify-content: center;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center; }


// .centered
//     display: flex
//     justify-content: center




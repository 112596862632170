body {
  margin: 0;
  padding: 0;
  background-color: #000; }

div, body {
  &::-webkit-scrollbar {
    width: 5px; }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #272727; }

  &::-webkit-scrollbar-thumb {
    background-color: #66458E; } }

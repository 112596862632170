.modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @include font(Montserrat, 300, 1rem, $white);
  backdrop-filter: blur(10px);
  background: $modalBg;
  transition: 0.5s;

  &__box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: $bgDark;
    border: solid 1px $primaryPurple;
    min-width: 270px;
    min-height: 13rem;
    max-width: 20rem;
    margin: 1rem;

    @media (max-width:769px) {
      font-size: 0.875rem; }

    h2 {
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      width: 100%;
      @include tablet-screen {
        font-size: 1.2rem; } }
    h4 {
      font-size: 1rem;
      font-weight: 400;
      margin-block: 0.5rem; }
    p {
      width: 100%;
      padding-block: 0.5rem;
      font-size: 0.875rem;
      text-align: left; }

    span {
      font-weight: 600;
      display: block;
      margin-top: 0.5rem; }

    a {
      font-weight: 600; } }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    &--centered {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem; } } }

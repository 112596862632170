@import "../../styles/helpers";

.parallax-partners {
  &__section {
    margin-top: 4rem;
    margin-bottom: 2rem;
    img {
      margin-bottom: 0;
      max-height: 4rem; } }
  &__container {
    border: 0.01rem solid $lighterGray;
    // box-shadow: 0 0 20px 1px $lighterGray
    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    padding: 1rem;
    margin-top: 4rem; }
  &__image {
    margin-bottom: 0; } }

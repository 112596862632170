// Scoped styles for the RelayzMeet component
.RelayzMeet {
  display: flex;
  flex-direction: column;
  height: 1000px; }

.fullScreenIframe {
  flex-grow: 1;
  width: 100%;
  height: 100%; // Make sure the parent's height is calculated correctly
  border: none; }

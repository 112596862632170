@import "../../styles/helpers";

.home {
  background-color: $black;
  max-height: 100vh;
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 692px) {
    transition: ease-in-out 1s;
    max-height: 100vh; }

  &-intro {
    &-wrapper {
      @include flex-props-center;
      width: 100%;
      height: 100vh;
      background: linear-gradient(270.32deg, #000000 24.27%, #66458E 36.14%, #B399FF 48.88%, #FFFFFF 55.91%, #B399FF 62.28%, #66458E 74.12%, #000000 85.1%);
      background-size: 200%;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
    &-title {
      @include font(Montserrat, 600, 96px, $black);
      max-width: 80%;
      text-align: left;
      margin: 0 auto;
      @media (max-width: 1000px) {
        transition: ease-in-out 1s;
        font-size: 64px; }
      @include phone-screen {
        font-size: 32px; } } }

  &-section {
    padding-top: 120px;
    transition: ease-in-out 1s;
    align-self: center;
    margin: 0 auto;
    padding-inline: 1vw;

    @media (max-width: 1240px) {
      padding-inline: 5vw;
      min-height: 55vh; }

    @include phone-screen {
      padding-top: 50px;
      max-width: 100%; }

    @include smaller-phone-screen {
      padding-top: 90px; }

    &-auto-height {}

    &__body {
      display: flex;
      &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6vw 15vw 6vw 15vw; } }

    &__hero--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10vw;
      @include font(Montserrat, 200, 0.875rem, $white);
      text-align: center;

      @media (max-width: 900px) {
        transition: ease-in-out 1s;
        font-size: 0.9rem; }
      @include phone-screen {
        display: flex;
        align-items: center;
        justify-content: center; } }

    &-adverts {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 1rem;
      @media (max-width: 1000px) {
        flex-direction: column; }

      &--news {
        flex: 1;
        width: 100%;
        max-height: 500px;
        @include font("Montserrat", 300, 1rem, $white);
        // -webkit-mask: linear-gradient(0deg, transparent, white 12%, white 90%, transparent)
 }        // mask-image: linear-gradient(0deg, transparent, white 12%, white 90%, transparent)

      &--socials {
        flex: 1;
        width: 100%;
        max-height: 500px; }

      &--message-container {
        max-height: 400px;
        overflow-y: auto;
        padding-right: 2rem;
        margin: 2.5rem 0 0 0;
        @include phone-screen {
          padding-right: 0; }
        h4 {
          @include font("Montserrat", 600, 1.2rem, $yellow); }
        p {
          margin-bottom: 1rem;
          text-align: justify;
          text-justify: inter-word;
          line-height: 1.3rem;
          &:first-child {
            margin-top: 2rem; } }
        strong {
          display: block; } }

      &--message {

        // border-bottom: 0.01rem solid $lightGray
        background: rgb(45, 45, 45);
        padding: 1.5rem 2rem 0.5rem;
        &:last-child {
          margin-bottom: 2rem; } }

      &--date {
          @include font("Montserrat", 600, 0.75rem, $lightGray); } }

    &-content {
      z-index: 1;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-image: url("../../assets/images/news-logo-section.png");
      background-repeat: no-repeat;
      background-position: 0;
      background-size: contain;

      h2 {
        @include phone-screen {
          margin-bottom: 0.5rem; } }

      h4 {
        margin-top: 1rem;
 } }        // font-size: 1.5rem

    &-images {
      position: relative;
      flex: 1;
      z-index: 3;
      img {
        width: 100%;
        height: auto; } }


    &-image {
      filter: brightness(0.8);
      transition: z-index 0s, filter 0.5s ease-in;
      &:hover {
        z-index: 2;
        filter: brightness(1.1); }

      &--nodes {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        @media (max-width: 1000px) {
          margin-top: 1rem; }
        img {
          padding-right: 2rem;
          max-width: 500px; } }

      &--rewards {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          margin-top: -2rem;
          padding-left: 4rem;
          max-width: 500px; } }

      &--sample {
        display: none;
        flex: 1;
        width: 100%;
        img {
          max-width: 400px;
          width: 100%; }
        @media (max-width: 1000px) {
          display: none;
          justify-content: center;
          align-items: center;
          margin-top: 2rem; }
        &-mobile {
          display: flex;
          img {
            padding: 1rem;
            max-width: 400px;
            width: 100%; }
          @media (min-width: 1000px) {
            display: none; } } }

      &--mobile {
        position: absolute;
        max-width: 100px;
        z-index: 0;
        left: 0;
        top: 0;
        transition: box-shadow 0.5s ease-in;
        &:hover {
          box-shadow: 1px 1px 5px $white; }
        @media (max-width: 1000px) {
          display: none; } }

      &--tablet {
        position: absolute;
        max-width: 250px;
        z-index: 1;
        left: 10%;
        top: 8rem;
        transition: box-shadow 0.5s ease-in;
        &:hover {
          box-shadow: 1px 1px 5px $white; }
        @media (max-width: 1000px) {
          display: none; } }

      &--desktop {
        position: absolute;
        top: 2rem;
        max-width: 400px;
        right: 0;
        z-index: -1;
        transition: box-shadow 0.5s ease-in;
        &:hover {
          box-shadow: 1px 1px 5px $white; }
        @media (max-width: 1000px) {
          display: none; } } } }

  &-hero {
    @include flex-props-center;
    padding-right: 10%;
    padding-left: 10%;

    &-title {
      text-align: center;
      max-width: 80%;
      @include font(Montserrat, 600, 96px, $white);
      color: $white;
      background: linear-gradient(180deg, #B399FF 5%, rgba(102,69,142,1)50%, rgba(102,69,142,1)50%, rgba(102,69,142,1)50%, #B399FF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include phone-screen {
        font-size: 64px; }

      @media (max-width: 768px) {
        transition: ease-in-out 1s;
        font-size: 42px; } } }

  &-description {
    @include flex-props-column-center;
    @include background-props;
    background-color: $black;
    max-width: 1200px;
    // margin: 0 10vw
    align-items: flex-start;
    flex-direction: row;
    min-height: 550px;
    @include phone-screen {
      margin: 0;
      align-items: center;
      width: 100%;
      min-height: 500px; }

    &-content-box {
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;
      //margin-right: 5vw
      @include phone-screen {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: center; }

      &-bottom {
        display: flex;
        padding: 2vw 15vw 2vw;
        object-fit: contain;
        @include phone-screen {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0;
          width: 99vw; }

        // @media (max-width: 450px)
        //transition: ease-in-out 1s
        //transition: ease-in-out 0.2s
        //   opacity: 0
        //   max-height: 0px
 }        //   max-width: 0px

      &-left {
        margin: 0vw 5vw 0vw -30vw;
        min-width: 32.5vw;
        align-self: center;
        transition: ease-in-out 0.2s;
        object-fit: contain;

        @media (max-width: 768px) {
          margin: 0 0 5vw; }

        @media (max-width: 768px) {

          transition: ease-in-out 0.2s;
          opacity: 0;
          max-height: 20rem;
          max-width: 20rem;
          margin: 0 0 0 0;
          display: none; }

        &-end {
          margin: 0vw 5vw 0vw -30vw;
          min-width: 32.5vw;
          align-self: center;
          max-height: 375px;
          object-fit: contain;

          @media (max-width: 768px) {

            transition: ease-in-out 0.2s;
            opacity: 0;
            max-height: 0px;
            max-width: 0px; } } }

      &-right {
        margin: 0vw -25vw 0vw 0vw;
        min-width: 32.5vw;
        align-self: center;
        object-fit: contain;

        @media (max-width: 768px) {

          transition: ease-in-out 0.2s;
          opacity: 0;
          max-height: 0px;
          max-width: 0px;
          margin: 0;
          display: none; }
        @media (max-width: 768px) {
          margin: 0; }

        &-end {
          margin: 0vw -30vw 0vw 5vw;
          min-width: 32.5vw;
          max-height: 200px;
          align-self: center;
          object-fit: contain;

          @media (max-width: 768px) {

            transition: ease-in-out 0.2s;
            opacity: 0;
            max-height: 0px;
            max-width: 0px; } } }

      &-center {

        min-width: 65vw;
        max-height: 25vw;
        align-self: center;
        margin: 5vw 0vw;
        object-fit: contain;

        @media (max-width: 768px) {

          transition: ease-in-out 0.2s;
          opacity: 0;
          max-height: 0px;
          max-width: 0px;
          display: none; } } }

    &-title {
      @include font(Montserrat, 300, 65px, $white);
      margin-bottom: 40px;
      max-width: 100%;
      text-shadow: 3px 3px $black;
      text-align: left;

      @media (max-width: 2048px) {
        transition: ease-in-out 1s;
        font-size: 48px;
 }        //max-width: 40%

      @media (max-width: 1280px) {
        transition: ease-in-out 1s;
 }        //max-width: 40%

      @media (max-width: 1000px) {
        transition: ease-in-out 1s;
        font-size: 42px;
        max-width: 100%;


        @include phone-screen {
          font-size: 2.2rem;

          margin-top: 2rem; } } }

    &-cta {
      @include flex-props-space-between;
      width: 100%;
      margin-bottom: 60px;

      @include phone-screen {
        flex-wrap: wrap; }

      &-text {
        @include font(Montserrat, 300, 22px, $white);
        max-width: 60%;
        line-height: 30px;
        @include phone-screen {
          font-size: 18px; }

        &-content-header {
          font-size: 1.5rem;
          width: 80vw;
          align-self: center;
          @include phone-screen {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 80vw; } }

        &-body {
          align-self: center;
          max-width: 800px;
          @include phone-screen {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0;
            width: 80vw; }
          &-right {
            //width: 30vw
            display: flex;
            flex-direction: column;
            text-align: right;
            margin-right: 31.5vw;
            transition: ease-in-out 0.2s;
            align-self: center;
            width: 30vw;
            max-width: 400px;
            @include phone-screen {
              display: flex;
              justify-content: center;
              margin: 0;
              width: 80vw; }

            @media (max-width: 768px) {
              transition: ease-in-out 0.2s;
              text-align: left;
              min-width: 80vw; } }

          &-left {
            width: 30vw;
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-left: 22vw;
            align-self: center;
            max-width: 400px;

            @include phone-screen {
              display: flex;
              justify-content: center;
              margin: 0;
              width: 80vw; }

            @media (max-width: 768px) {
              transition: ease-in-out 1s;
              margin: 0;
              text-align: left;
 } }              //min-width: 85vw

          &-small {
            max-width: 30vw;
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-right: 35vw;
            font-size: 22px;
            font-weight: 300;
            align-self: center;
            @include phone-screen {
              text-align: left;
              min-width: 78vw;
              margin: 0; }

            @media (max-width: 768px) {
              transition: ease-in-out 1s;
              margin-left: {}
              text-align: left;
              min-width: 75vw; }

            &-end {
              max-width: 30vw;
              display: flex;
              flex-direction: column;
              text-align: left;
              //margin-right: 5vw
              font-size: 22px;
              font-weight: 300;
              align-self: center;
              @include phone-screen {
                text-align: left;
                min-width: 75vw;
                margin: 0; }

              @media (max-width: 768px) {
                transition: ease-in-out 1s;
                margin-right: 0vw;
                margin-left: -8vw;
                text-align: left;
                min-width: 75vw; } } } }

        @media (max-width: 900px) {
          transition: ease-in-out 1s;
          font-size: 22px; }

        @include phone-screen {
          max-width: 100%;
          margin-bottom: 30px; } }

      &-button {
        background: transparent;
        font-weight: 400;
        font-size: 20px;
        color: $white !important;
        border-radius: 45px;
        padding: 5px 30px;
        border: solid 3px $primaryPurple;
        display: block;
        text-align: center;
        margin: 0 auto 30px;
        @include font(Montserrat, 300, 22px, $primaryPurple);

        &:hover {
          border-color: $primaryPurple !important;
          background-color: $bgPurple !important; }

        &-navi {
          background: transparent;
          border-radius: 30px;
          padding: 5px 20px;
          border: 3px solid $primaryPurple;
          font-family: Montserrat;
          font-weight: 300;
          color: $white;
          max-height: 40px;
          font-size: 18px !important;

          &:hover {
            border-color: $primaryPurple !important;
            background-color: $bgPurple !important; } }

        @media (max-width: 900px) {
          transition: ease-in-out 1s;
          font-size: 18px !important; } } } }

  &-media {
    @include flex-props-space-between;
    flex-wrap: wrap;
    padding: 5%;
    background: $black;
    width: 100%;
    height: 100vh;
    max-width: 95%;

    @include small-phone-screen {
      @include flex-props-column-space-between;
      align-items: center;
      height: auto;
      padding-bottom: 100px; }

    @media (min-width: 1600px) {
      transition: ease-in-out 1s;
      max-width: 1500px;
      max-height: 900px; }

    @media (min-width: 2048px) {
      transition: ease-in-out 1s;
      max-width: 2000px;
      max-height: 1250px; }

    &-section {
      padding-top: 0;
      @include flex-props-center; }

    &-item {
      width: 49%;
      height: 48%;
      cursor: pointer;
      @include flex-props-column-space-between;
      @include small-phone-screen {
        width: 80vw;
        height: 80vw;
        margin-bottom: 25px; }

      &-large {
        flex-grow: 2;
        @include flex-props-space-between;
        padding: 25px;
        @include small-phone-screen {
          @include flex-props-column-space-between;
          align-items: center;
          height: 130vw; } }

      &-title, &-text, &-date, &-subtitle {
        z-index: 1; }

      &-title {
        @include font(Montserrat, 400, 42px, $primaryPurple);
        padding: 40px 30px;
        width: 100%;
        text-align: left;
        text-shadow: 1px 1px $darkGrayDetails;

        @media (max-width: 1280px) {
          transition: ease-in-out 1s;
          font-size: 22px; }

        @include phone-screen {
          font-size: 24px; } }

      &-text {
        @include font(Montserrat, 300, 30px, $white);
        flex-grow: 2;
        padding: 10px 30px;
        text-align: left;
        @media (max-width: 1280px) {
          transition: ease-in-out 1s;
          font-size: 22px; }

        @include phone-screen {
          font-size: 21px; } }

      &-subtext {
        font-size: 22px;
        color: $white;
        text-shadow: 1px 1px $mediumGray;
        @media (max-width: 1280px) {
          transition: ease-in-out 1s;
          font-size: 18px; } }

      &-date {
        @include font(Montserrat, 300, 42px, $white);
        text-align: right;
        @media (max-width: 1280px) {
          transition: ease-in-out 1s;
          font-size: 22px; }

        @include phone-screen {
          font-size: 24px; } }

      &-subtitle {
        @include font(Montserrat, 300, 30px, $white);
        text-align: right;
        margin-bottom: 20px;
        display: block;
        @media (max-width: 1280px) {
          transition: ease-in-out 1s;
          font-size: 22px; }

        @include phone-screen {
          font-size: 24px; } }

      &-content, &-social {
        @include flex-props-column-space-evenly;
        align-items: flex-end;
        height: 100%; }

      &-social {
        z-index: 1;
        @include small-phone-screen {
          @include flex-props-space-evenly;
          width: 100%; }

        &-link {
          width: 45px;
          height: 45px;
          border-radius: 100%;
          background-color: $darkPurple;
          @include flex-props-center; }

        &-icon {
          height: 24px; } }

      &-content {
        width: 70%;
        @include small-phone-screen {
          width: 90%; }

        &-text {
          z-index: 1;
          .home-media-item-text {
            @include font(Montserrat, 300, 22px, $white);
            flex-grow: 0;
            padding: 0;
            text-align: right;

            @media (max-width: 1280px) {
              transition: ease-in-out 1s;
              font-size: 21px; }

            @include phone-screen {
              font-size: 19px; } } } }

      &:hover {
        .black-background {
          background-color: $black; } } }

    .meet-background, .token-background, .christmas-background {
      @include background-props;
      position: relative;
      border-radius: 30px;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $lightGray;
        transition: background-color .5s ease;
        z-index: 0;
        border-radius: 30px; }

      &:hover:after {
        background-color: $black; } }

    .meet-background {
      background-image: url("../../assets/images/news-meet-background.png");

      .home-media-item-title {
        color: $primaryPurple;
        transition: color .5s ease; }

      &:hover {
        .home-media-item-title {
          color: $primaryPurple; } } }

    .token-background {
      background-image: url("../../assets/images/news-token-background.png"); }

    .christmas-background {
      background-image: url("../../assets/images/news-christmas-background.png");
      &:after {
        background-color: $black; }

      &:hover {
        .snow-animation-wrapper {
          opacity: 1; } }

      .snow-animation-wrapper {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        z-index: 0;
        opacity: 0;
        transition: opacity .5s ease;

        .snow-animation {
          position: relative;
          height: 100%;
          width: 100%;
          overflow: hidden;

          .snowflake {
            --size: 1vw;
            width: var(--size);
            height: var(--size);
            background: $white;
            border-radius: 50%;
            position: absolute;
            top: -5vh; }

          @keyframes snowfall {
            0% {
              transform: translate3d(var(--left-ini), 0, 0); }

            100% {
              transform: translate3d(var(--left-end), 100vh, 0); } }

          @for $i from 1 through 50 {
            .snowflake:nth-child(#{$i}) {
              --size: #{random(5) * 0.2}vw;
              --left-ini: #{random(20) - 10}vw;
              --left-end: #{random(20) - 10}vw;
              left: #{random(100)}vw;
              animation: snowfall #{5 + random(10)}s linear infinite;
              animation-delay: -#{random(10)}s; } }

          .snowflake:nth-child(6n) {
            filter: blur(1px); } } } }

    .black-background {
      background-color: $black;
      transition: background-color .5s ease; } }

  &-benefits {
    padding-top: 65px;
    position: relative;

    @include phone-screen {
      padding-bottom: 200px; }

    &-slider {
      padding-left: 30px;
      padding-right: 30px;
      margin: 0 auto;

      @media (min-width: 700px) {
        transition: ease-in-out 1s;
        max-width: 1000px; }

      @media (min-width: 900px) {
        transition: ease-in-out 1s;
        max-width: 1000px; }

      @media (min-width: 1200px) {
        transition: ease-in-out 1s;
        max-width: 1000px; } }

    &-title {
      @include font(Montserrat, 300, 48px, $white);
      text-align: center;
      padding: 35px;

      @include phone-screen {
        margin: 0 auto 20px;
        text-align: center; }

      @media (min-width: 800px) {
        transition: ease-in-out 1s;
        @include font(Montserrat, 300, 56px, $white);
        text-align: center;
        padding: 50px; }

      @media (min-width: 1200px) {
        transition: ease-in-out 1s;
        @include font(Montserrat, 300, 56px, $white);
        text-align: center;
        padding-top: 20px;
        padding-bottom: 40px; } }

    &-partners {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: ro;
      flex-wrap: wrap;
      margin: auto;
      max-width: 80vw;
      @include phone-screen {
        padding: 2rem 2rem; }
      @media (min-width: 800px) {
        transition: ease-in-out 1s;
        padding: 2rem 4rem; }
      @media (min-width: 1200px) {
        transition: ease-in-out 1s;
        padding: 0vw 5vw; }

      &-item {
        margin-bottom: 5vw;
        margin-right: 2rem;
        max-height: 100px;
        @include phone-screen {
          height: 15vw; }
        @media (min-width: 560px) {
          transition: ease-in-out 1s;
          height: 10vw; }
        @media (min-width: 1200px) {
          transition: ease-in-out 1s;
          height: 5vw;
          min-height: 2vw; } } } }

  &-footer-wrapper {
    width: 80%;
    position: relative;
    bottom: 0;
    margin: 0 auto;
    // height: 1px
    // background-color: $primaryPurple
    border: none;
    align-items: center;
    display: flex;
    flex-direction: column; }

  &-team {

    &-slider {
      & .slider-slide-content {
        align-items: center;
        text-align: center; }

      & .slider-slide-content-icon {
        height: 140px;
        width: fit-content;
        margin-bottom: 50px; } }

    &-title {
      @include font(Montserrat, 400, 48px, $white);
      margin: 0 0 100px 70px;

      @media (min-width: 800px) {
        transition: ease-in-out 1s;
        @include font(Montserrat, 400, 64px, $white);
        margin: 0 0 100px 100px; }

      @media (min-width: 1200px) {
        transition: ease-in-out 1s;
        @include font(Montserrat, 400, 96px, $white); } } } }

.hero__mockup {

  width: 568px;
  min-width: 400px;
  display: flex;
  &:hover {
    filter: brightness(1.2); }

  @media (max-width: 1000px) {
    margin-left: 0vw;
    margin-top: 2rem;
    margin-bottom: -2rem;
    display: flex;
    align-self: center;
    width: 15rem;
    height: 15rem;
    background-repeat: no-repeat;
    display: none; } }


.hero__mockup-mobile {

  width: 568px;
  height: 436px;

  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/mockup.png');
  background-size: contain;
  display: flex;

  @media (max-width: 1000px) {
    margin-left: 0vw;
    margin-top: 2rem;
    margin-bottom: -2rem;
    display: flex;
    align-self: center;
    width: 15rem;
    height: 15rem;
    background-repeat: no-repeat;
    display: none; } }

.hero__mockup--teams {
  //margin-top: 20rem
  //margin-right: 5vw
  width: 20rem;
  background-color: transparent;
  background-image: url('../../assets/images/sectionIcons/team01.png');
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 80vw;

  @include phone-screen {
    align-self: center;
    margin: -5vw 5vw;
    height: 14rem; } }

// .mobile__body
//   display: flex
//   align-items: center
//   justify-content: center
//   align-self: center
//   width: 95vw
//   padding: 0 vw
//   object-fit: contain
.mobile-only {
  @media (min-width: 768px) {
    transition: 0.5s;
    display: none;
    opacity: 0; } }

.nft__images {
  display: flex;

  justify-content: center;
  margin: -5vw 10vw; }

/* Tooltip container */
.toolTip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black /* If you want dots under the hoverable text */; }

/* Tooltip text */
.toolTip .toolTipText {
  visibility: hidden;
  width: 160px;
  background-color: $darkGray;
  color: $white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid $white;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 105%;

  @include phone-screen {
    top: -15px;
    top: 110%;
    z-index: 1;
    width: 120px; } }

/* Show the tooltip text when you mouse over the tooltip container */
.toolTip:hover .toolTipText {
  visibility: visible; }

.top_section {
  margin-top: 5rem;
  @include phone-screen {
    margin-top: 8rem; } }

// .node_alignment
//   +phone-screen
//     margin-left: 5vw

.flex__column {
  display: flex;
  flex-direction: column;
  align-items: center; }

.hero__meet-wrapper {
  //max-width: 50%
  flex-direction: column;
  gap: 1rem;

  p {
    display: block;
    font-family: "Montserrat";
    font-weight: 400;
    color: $white;
    margin: 0.5rem 0 2rem;
    width: 100%; }

  @media (max-width: 1000px) {
    max-width: 100%; } }

.hero__mockup-button {
  width: fit-content; }




.update_modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @include font(Montserrat, 300, 1rem, $white);
  backdrop-filter: blur(10px);
  background: $bgDark;
  transition: 0.5s;

  &__box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: black;
    border: solid 1px $primaryPurple;
    min-width: 270px;
    min-height: 13rem;
    max-width: 20rem;
    margin: 1rem;

    @media (max-width:769px) {
      font-size: 0.875rem; }

    h2 {
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1.4rem;
      width: 100%;
      @include tablet-screen {
        font-size: 1.2rem; } }
    h4 {
      font-size: 1rem;
      font-weight: 400;
      margin-block: 0.5rem; }
    p {
      width: 100%;
      padding-block: 0.5rem;
      font-size: 0.875rem;
      text-align: left; }

    span {
      font-weight: 600;
      display: block;
      margin-top: 0.5rem; } }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    &-button {
      font-family: "Montserrat";
      cursor: pointer;
      border-radius: 25px;
      color: $primaryPurple;
      border: solid 2px $primaryPurple;
      padding: 5px 10px;
      background: transparent;
      transition: color, background 0.5s;
      font-size: 0.875rem;
      font-weight: 400;

      @include smaller-phone-screen {
        font-size: 0.725rem; }

      &:hover {
        color: $white;
        text-shadow: 0 0 8px $bgPurple;
        background: $primaryPurple; } } } }

.text-right {
  text-align: right;
  @include phone-screen {
    text-align: left; } }

.text-left {
  text-align: left; }

.text-center {
  text-align: center;
  width: 100%;
  @include phone-screen {
    text-align: left; } }

.home__section-wrapper {
  width: 100%;
  font-family: "Montserrat";
  color: $white;
  background: $sectionBg;

  .home-section {
    background: transparent;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    min-height: 550px;

    @media (max-width: 1000px) {
      flex-direction: column; } } }

.animation__fade-initial {
    opacity: 0;
    transform: translateY(-15px) /* add initial transform */;
    transition: opacity 0.25s ease-in-out 0.25s, transform 0.25s ease-in-out 0.25s, /* add transform transition */; }

.animation__fade-final {
    opacity: 1;
    transform: translateY(0) /* add final transform */; }


.animation__fade-initial-icon {
    opacity: 0;
    transition: opacity 0.25s ease-in-out 0.25s;
    margin-top: 1rem; }

.animation__fade-final-icon {
    opacity: 1;
    margin-top: 1rem; }






.animation__landing-initial {
    opacity: 0;
    transform: translateY(30px); }

.animation__landing-final {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out; }


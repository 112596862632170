@import "../../styles/helpers";

.about {
  background-color: $black;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @include phone-screen {
    margin-top: 10px;
    margin-bottom: 20px; }

  &-slider {
    margin-left: 100px; }

  &-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
    @include phone-screen {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      padding-left: 5vw; }


    @include background-props;
    background-color: $black;


    &-title {
      margin-right: 80px; }


    &-text {
      @include font(Montserrat, 300, 2rem, $white);
      font-size: 0.875rem;
      max-width: 50vw;
      //margin-top: 20rem
      //margin-left: 5vw
      margin-right: 2rem;
      @media (max-width: 1920px) {
 }       // max-width: 40vw

      @include phone-screen {
        font-size: 0.875rem;
        max-width: 100%;
        //margin-top: 120px
        margin-bottom: 80px; } } }

  &-team {
    @include flex-props-column-center;
    //margin-bottom: 100px


    &-stock-image {
      height: 80vh; } }

  &-title {
    margin: 2rem auto 3rem;
    text-align: center; }

  &-subtext {
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    font-size: 0.875rem;
    color: $white;
    text-align: justify;
    margin-bottom: 2rem;
    align-self: center;
    max-width: 1000px;
    padding: 0;

    @media (max-width: 880px) {
      font-size: 0.875rem;
      margin-bottom: 30px;
      width: 100%;
 } }      // padding: 0vw 5vw 5vw

  &-team {
    //min-height: 100vh
    // padding-top: 3rem
    @include flex-props-column-space-evenly;

    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 80vw;
      margin-bottom: 1rem;
      align-self: center;
      gap: 5rem; }


    &-member {
      @include flex-props-center;
      width: 400px;
      height: 260px;
      margin-bottom: 30px;
      margin-right: 11px;
      max-width: 90vw;

      &-image {
        width: 180px;
        height: 90%;
        border-radius: 30px 0 0 30px; }

      &-wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: $white;
        border-radius: 0 30px 30px 0;
        height: 90%; }


      &-name {
        @include font(Montserrat, 400, 1.35rem, $primaryPurple);
        //margin-top: 10px
        margin-bottom: 5px;
        width: 100%;
        @include phone-screen {
          font-size: 1.25rem; } }

      &-details {
        @include font(Montserrat, 400, 1rem, $bgDark);
        margin-bottom: 20px;
        width: 100%;
        @include phone-screen {
          font-size: 1rem; } }
      &-description {
        @include font(Montserrat, 300, 1rem, $bgDark);
        width: 100%;
        margin-top: -5px;
        @include phone-screen {
          font-size: 0.875rem; }
        @media (max-width: 340px) {
          font-size: 0.8rem; } } } }

  &-roadmap {
    min-height: 85vh;
    display: flex;
    flex-direction: column;

    &-timeline {
      margin-top: 0px;
      padding: 100px;
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include phone-screen {
        padding: 0px;
        justify-content: flex-start; }

      &-slider {
        position: relative;
        z-index: 1;

        .slider-button-wrapper {
          top: 20px;
          right: 150px;
          z-index: 5;
          width: fit-content;
          &-prev {
            left: 150px; } }



        &:before, &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 40%;
          z-index: 1; }

        &:before {
          left: 0;
          background: linear-gradient(.25turn, $black, 80%, transparent);
          @include phone-screen {
            width: 40%; } }

        &:after {
          right: 0;
          background: linear-gradient(.75turn, $black, 80%, transparent);
          width: 40%; }

        .slick-slider {
          z-index: 2; } } } } }

.roadmap {

  &__section {}
  margin-bottom: 200px;
  padding-bottom: 200px;

  &__subtitle {
        margin-top: 0px;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include phone-screen {
          padding: 0px;
          justify-content: flex-start; } }

  &__time2022 {
        position: relative;
        left: 0px; } }




.section__wrapper {
  margin: 0 5vw; }

@import "../../styles/helpers";

.node_item {
  position: relative;
  display: flex;
  flex-direction: column;
  &-container {
    width: 100%;
    background: $darkGray;
    border-bottom: 0.5px solid $borderGray;
    height: 2rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.3125rem;
    &:hover {
      cursor: pointer;
      background: $bgDeepPurple; }
    &--selected {
      background: $bgDeepPurple;
      @include tablet-screen {
        background: $darkGray;
        &:hover {
          background-color: $darkGray; } }
      &-notowner {
        @include tablet-screen {
          background: $bgDeepPurple; }
        &:hover {
          background: $bgDeepPurple; } } }

    @include tablet-screen {
      height: 5rem;
      overflow-x: hidden;
      &:hover {
 } } } }        // background-color: $darkGray

.node_item-row-container {
  flex: 1;
  &--no-mobile {
    @include tablet-screen {
      display: none; } }
  &--no-small-mobile {
    @include small-phone-screen {
      display: none; } }
  &-button {
    @include flex-props-center;
    flex-wrap: nowrap;
    background: transparent;
    color: $white;
    opacity: 0.5;
    width: 4.5rem;
    @include tablet-screen {
      opacity: 1; }
    &:first-child {
      margin-right: 1rem; }
    &:last-child {
      margin-right: 0.5rem; }
    &:hover {
      opacity: 1; }
    &-image {
      display: inline-block;
      margin-right: 0.2rem;
      width: 18px;
      height: 18px; }
    &-text {
      @include font(Montserrat, 400, 0.8rem, $white); }
    &:disabled {
      opacity: 0.3;
      &:hover {
        opacity: 0.3;
        cursor: not-allowed; } } } }

.node_item-row-buttons {
  position: absolute;
  right: 2rem;
  top: 0.4rem;
  visibility: visible;
  @include flex-props-center;
  flex-wrap: nowrap;
  @include tablet-screen {
    display: none; }
  &--selected {
    visibility: visible;
    @include tablet-screen {
      right: 0;
      top: 0;
      bottom: 0;
      padding-inline: 4rem;
      width: 6rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background: $bgDeepPurple;
      border-bottom: 0.5px solid $borderGray; }
    .node_item-row-container-button {
      &:first-child {
        margin-left: 0.4rem; } } } }

.node_item-row-heading {
  display: none;
  @include font(Montserrat, 400, 0.875rem, $white); }

.node_item-row-ranking {
  margin-right: 0.2rem;
  width: 10px;
  height: 10px;
  vertical-align: baseline;
  @include tablet-screen {
    display: block;
    margin-left: 1.7rem;
    position: absolute;
    top: -0.8rem; }
  @media (max-width: 420px) {
    margin-left: 0; }
  &--gold {
    fill: $gold; }
  &--silver {
    fill: $silver; }
  &--bronze {
    fill: $bronze; } }

.node_item-row-unit {
  flex: 1;
  text-align: flex-start;
  position: relative;
  &-last {
    min-width: 12rem; }
  &-state {
    z-index: 1;
    display: none;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    left: 0;
    transform: translate(50%, -100%);
    &--green {
      background-color: $darkGreen; }
    &--yellow {
      background-color: $darkYellow; }
    &--red {
      background-color: $darkRed; }
    @media (max-width: 420px) {
      display: flex;
      z-index: 1;
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      left: 0;
      transform: translate(50%, -100%);
      border-radius: 8px;
      height: 2.5rem;
      width: 6rem;
      left: -3.5rem;
      top: 1.35rem; } }



  // Will break line for screens smaller than 620px
  &-ownerId {
    z-index: 2;
    @media (max-width: 750px) {
      display: block; } }

  &:first-child {
    min-width: 10rem;
    @include tablet-screen {
      min-width: 0; }
    @include small-phone-screen {
      min-width: 6rem; } } }

.node_item-status {
  &--red {
    max-width: 4rem;
    border-radius: 5px;
    padding: 2px 1px;
    font-weight: 400;
    text-align: center;
    background-color: $darkRed;
    color: $red; }
  &--green {
    max-width: 4rem;
    width: 4rem;
    border-radius: 5px;
    padding: 2px;
    font-weight: 400;
    text-align: center;
    background-color: $darkGreen;
    color: $green; }
  &--yellow {
    max-width: 5.5rem;
    border-radius: 5px;
    padding: 2px;
    font-weight: 400;
    text-align: center;
    background-color: $darkYellow;
    color: $yellow; } }

@import "../../../styles/_helpers.sass";

.network {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8rem 1rem 1rem 2rem;
    margin-left: 5vw;
    margin-right: 5vw;

    &__sections-referral {
        &-container {
            display: flex;
            align-items: center;
            margin-block: 1rem;
            @include small-phone-screen {
                flex-direction: column;
                align-items: flex-start; } }
        &-icon {
            width: 3.5rem;
            margin-left: 1rem;
            @include small-phone-screen {
                margin-left: 0;
                margin: 2rem 0 0.5rem 0;
                align-self: center; } }
        &-title {
            font-size: 2rem;
            margin-top: 4rem;
            margin-bottom: 4rem;
            align-self: center; }
        &-subtitle {
            font-size: 1.4rem;
            &--centralized {
                @include font(Montserrat, 500, 1.4rem, $primaryPurple);
                font-size: 1.4rem;
                margin: 3rem 0 2rem;
                @include small-phone-screen {
                    text-align: center; } } }
        &-text {
            font-size: 1.2rem; }
        &-content {
            display: flex;
            width: fit-content;
            // margin-bottom: 3rem
            @include small-phone-screen {
                flex-direction: column;
                margin: auto; }
            &-login {
                margin-bottom: 3rem;
                margin-left: 0.25rem;
                margin-top: 0.25rem; } }
        &-code {
            display: inline-block;
            text-align: center;
            padding: 1.8rem 3rem;
            border-radius: 15px;
            background-color: $darkBgPurple;
            border: 0.05rem solid $primaryPurple;
            min-width: 100%;
            @include font(Montserrat, 500, 1.2rem, $white);
            word-break: break-all; }
        &-buttons {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            @include small-phone-screen {
                // flex-direction: row
                // justify-content: center
                // margin: 1rem 0 3rem
 }                // gap: 1rem
            button {
                outline: 0;
                filter: brightness(0.9);
                transition: filter 0.2s ease-in-out;
                padding: 0.5rem 1.2rem;
                border-radius: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include font(Montserrat, 500, 1rem, $white);
                &:hover {
                    filter: brightness(1.1); }
                img {
                    margin-left: 0.5rem; } }
            &--copy {
                background: $primaryPurple; }
            &--submit {
                background: $primaryPurple;
                min-height: 3rem;
                @media (max-width: 500px) {
                    min-height: 2.4rem; } }
            &--share {
                background: transparent;
                // box-shadow: inset 0 0 0 0.1rem $primaryPurple
                border: 0.1rem solid $primaryPurple; } } } }

.network_incentives {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    justify-content: center;
    margin: 6rem 1rem 1rem 2rem;
    @media (max-width: 692px) {
        margin: 0; }

    &__sections-incentive {
        max-width: 1000px;
        margin-bottom: 2rem;
        @media (max-width: 692px) {
            max-width: 100%; } }

    &__central {
        display: flex;
        justify-content: center;
        margin: 2rem 0; }

    &__join-hero {
        @include font(Montserrat, 300, 2rem, $white);
        margin-bottom: 40px;
        white-space: nowrap;

        @media (max-width: 2048px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }

        @media (max-width: 1280px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }

        @media (max-width: 900px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }

        @include phone-screen {
            font-size: 1.5rem;
            max-width: 90%;
            white-space: normal; }


        &-subtitle {

            margin: 2rem 0;

            @media (max-width: 768px) {
                font-size: 1.75rem;
                max-width: 90%; } } } }

.node {
    &__button {
        width: 97.25% !important;
        height: 5rem;
        font-size: 2.5rem;
        border: 2px solid $white;
        border-radius: 12px;
        margin: 2rem 0;
        background-color: black;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center; }
    &__reward {
        display: flex;
        margin: 2rem 0;
        @media (max-width: 768px) {
            font-size: 2.2rem;
            display: flex;
            flex-direction: column;
            justify-items: center;
            margin: 0; } }
    &__prop-mobile {

        margin: 0rem 2rem 0rem 0rem;
        @media (max-width: 768px) {
            margin: 0; } } }

.node__prop-mobile > img:nth-child(1) {
    max-height: 60vw; }

@import "./basis/_colors";
@import "./abstracts/_mixins";

.purple-text {
  color: $primaryPurple !important; }

nav .purple-text {
  color: $primaryPurple; }

nav .purple-text:visited {
  color: $primaryPurple; }

.capitalize-text {
  text-transform: capitalize; }

.header__font {
  font-family: "Montserrat";
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  @include phone-screen {
      font-size: 1.5rem; }
  &--left {
      font-family: "Montserrat";
      text-align: left;
      font-size: 2rem;
      width: 100%;
      margin: 2rem 0;
      @include phone-screen {
          font-size: 1.5rem; } } }

.subHeader__font {
  font-family: "Montserrat";
  font-size: 1.3rem;
  @include phone-screen {
      font-size: 1rem; } }

.paragraphs {
  font-size: 1rem;
  @include phone-screen {
      font-size: 0.875rem; } }

.large-title {
  @include font(Montserrat, 300, 4rem, $primaryPurple);
  max-width: 100%;
  text-shadow: 3px 3px $black;

  @media (max-width: 2048px) {
    transition: ease-in-out 1s;
    font-size: 3rem; }

  @media (max-width: 1000px) {
    font-size: 2.625rem;
    max-width: 100%; }

  @include phone-screen {
    font-size: 2.2rem; }

  @include smaller-phone-screen {
    font-size: 1.8rem; } }

// .title
//   font-size: 2rem
//   +phone-screen
//     font-size: 1.5rem

// .subtitle
//   font-size: 1.375rem
//   +phone-screen
//       font-size: 1rem

.page-container {
  @include font(Montserrat, 400, 0.875rem, $white);
  margin: 8rem 1rem 1rem 1rem;
  @media (max-width: 692px) {
    margin-left: 5vw;
    margin-right: 5vw; } }

.page-content {
  min-height: 50vh;
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: 692px) {
    max-width: 100%; } }

.italic {
   font-style: italic; }

.card__side-by-side--wrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @include phone-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-around; } }

.card__side-by-side--item {
  max-width: 45%;
  gap: 1rem;
  @include phone-screen {
    max-width: 100%; } }


@mixin circle__display {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  border: 3px solid #B399FF;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
  font-size: 2.625rem;
  font-weight: 400;
  color: #B399FF; }


.margin__top {
  margin-top: 1rem; }

.margin__bottom {
  margin-bottom: 1rem; }

.clickable {
  cursor: pointer; }

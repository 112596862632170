@import "../../../styles/_helpers.sass";

.body_nodes {
  margin-bottom: 2rem;
  margin-top: -2rem;
  // padding-top: 8rem
  display: flex;
  flex-direction: column;
  // width: 95vw
  max-width: 1000px;

  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: $white;

  // +phone-screen
  //   padding-top: 3rem

  @include small-phone-screen {
    width: 90vw; } }

// .node_list-title
//   padding: 0 0 2rem 1rem
//   +small-phone-screen
//     font-size: 1.5rem
//     line-height: 1.8rem

// .header_nodes
//   display: flex
//   flex-direction: column
//   justify-items: center
//   // +phone-screen
//     // flex-row: column-reverse

// .node_header
//   display: flex
//   flex-direction: row
//   justify-content: center
//   align-items: center
//   font-weight: 900
//   margin-bottom: .3125rem
//   background: $navigationBg
//   border-bottom: 0.5px solid $borderGray
//   height: 2.75rem
//   padding-left: 1rem
//   +phone-screen
//     display: none

// .node_header-unit
//   flex: 1
//   text-align: flex-start
//   &:first-child
//     min-width: 10rem

.node_node-row {
  background: $darkGray;
  border-bottom: 0.5px solid $borderGray;
  height: 2rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: $bgDeepPurple; }
  @include phone-screen {
    background: $black;
    border: 1px solid $borderGray;
    padding: 0.75rem 1.5rem 1.5rem 1.5rem;
    border-radius: 15px;
    flex-direction: column;
    max-width: 350px;
    height: auto;
    align-self: center; } }

.node_node-row-focused {
  background: $bgDeepPurple; }

.node_node-row-container {
  flex: 1;
  @include flex-props-center;
  flex-wrap: nowrap;
  @include phone-screen {
    display: grid;
    grid-template-columns: minmax(80px, 125px) minmax(80px, 125px);
    margin-bottom: 0.2rem;
    &:nth-child(2) {
      margin-bottom: 0; } }
  &-button {
    @include flex-props-center;
    flex-wrap: nowrap;
    background: transparent;
    color: $white;
    opacity: 0.5;
    &:first-child {
      margin-right: 1rem; }
    &:last-child {
      margin-right: 0.5rem; }
    &:hover {
      opacity: 1; }
    &-image {
      display: inline-block;
      margin-right: 0.2rem;
      width: 18px;
      height: 18px; }
    &-text {
      @include font(Montserrat, 500, 0.8rem, $white); } } }

.node_node-row-heading {
  display: none;
  @include font(Montserrat, 500, 0.875rem, $white);
  @include phone-screen {
    display: inline-block;
    margin-right: 2rem;
    justify-self: left; } }

.node_node-row-unit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  flex: 1;
  text-align: flex-start;
  &:first-child {
    min-width: 10rem; }
  @include phone-screen {
    justify-self: end;
    &:first-child {
      @include font(Montserrat, 500, 1.375rem, $mediumGray);
      margin-bottom: 0.625rem;
      @include smaller-phone-screen {
        font-size: 1.1rem; } } } }

.button_nodes {
  background: transparent;
  font-weight: 400;
  font-size: 20px;
  color: $mediumGray !important;
  border-radius: 45px;
  padding: 5px 30px;
  border: solid 3px $primaryPurple;
  @include flex-props-center;
  margin: 0px 0 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: $primaryPurple;
  width: 18rem;

  &:hover {
    background-color: $primaryPurple; } }

// .home-description-cta-button
//   /* display: block; */
//   flex-direction: column
//   justify-items: revert-layer

.button_wrapper {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0; }

.status_signal {
  max-width: 1rem; }

.nodes_limit-height {
  max-height: 400px;
  overflow-y: auto;
  @include phone-screen {
    padding-inline: 1rem;
    max-height: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden; }
  @include smaller-phone-screen {
    padding-inline: 0;
    font-size: 0.8rem; } }

.node_status_signal-green {
  text-align: center;
  background-color: $darkGreen;
  width: 5rem;
  border-radius: 5px;
  padding: 2px;
  color: $green;
  font-weight: 400;
  @include phone-screen {
    display: inline-block;
    @include font(Montserrat, 500, 0.9375rem, $green);
    width: auto;
    padding: 1px 6px; }
  @include smaller-phone-screen {
    font-size: 0.8rem; } }

.node_status_signal-red {
  text-align: center;
  background-color: $darkRed;
  width: 5rem;
  border-radius: 5px;
  padding: 2px;
  color: $red;
  font-weight: bold;
  @include phone-screen {
    display: inline-block;
    @include font(Montserrat, 500, 0.9375rem, $red);
    width: auto;
    padding: 1px 6px; }
  @include smaller-phone-screen {
    font-size: 0.8rem; } }

.node_status_signal-yellow {
  text-align: center;
  background-color: $darkYellow;
  width: 5rem;
  border-radius: 5px;
  padding: 2px;
  color: $yellow;
  font-weight: bold;
  @include phone-screen {
    display: inline-block;
    @include font(Montserrat, 500, 0.9375rem, $yellow);
    width: auto;
    padding: 1px 6px; }
  @include smaller-phone-screen {
    font-size: 0.8rem; } }

.node_status_signal-gray {
  text-align: center;
  background-color: $bgGray;
  width: 5rem;
  border-radius: 5px;
  padding: 2px;
  color: $white;
  font-weight: bold;
  @include phone-screen {
    display: inline-block;
    @include font(Montserrat, 500, 0.9375rem, $white);
    width: auto;
    padding: 1px 6px; }
  @include smaller-phone-screen {
    font-size: 0.8rem; } }

.management_node-search-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2rem;
  @include small-phone-screen {
    flex-direction: column; } }

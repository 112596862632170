@import "../../../styles/helpers";

.subheader {
  background: $subNavBg;
  backdrop-filter: blur(10px);
  height: 30px;
  @include flex-props-center;
  @include menu-size__max {
    display: none; }

  &-solid {
    border-bottom: 0.5px solid $borderGray; }

  &-link {
    @include font(Montserrat, 400, 13px, $white);
    transition: .3s ease color;
    margin: 0 15px;
    padding: 2px 10px;
    text-shadow: 1px 1px 3px black;

    &:hover {
      color: $primaryPurple; }

    @include phone-screen {
      margin: 0 15px 0 0;
      padding: 2px 5px;
      font-size: 16px;
      &:hover {
        font-weight: 400;
        color: $white; } } }

  @include phone-screen {
    background: transparent;
    padding: 0;
    border: none;
    justify-content: flex-start; } }

.sublink-active {
  color: $primaryPurple; }

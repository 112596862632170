@import "../../../styles/helpers";

.management {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_main-card {
    border-radius: 30px;
    padding: 1.75rem 2rem 2.5rem 2rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    @include tablet-screen {
      flex-direction: column-reverse;
      align-items: center;
      border: 1px solid $mediumGray; }
    @include smaller-phone-screen {
      border: none; }
    &-info {
      display: flex;
      flex-direction: column;
      @include tablet-screen {
        margin-top: 2rem; }
      &-toolbar {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    &-title {
      @include font(Montserrat, 400, 1.375rem, $mediumGray);
      text-align: center;
      padding: 0 0 2.5rem; }
    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0 0 0.35rem 0;
      gap: 1rem; } }

  &-button {
    background: transparent;
    border-radius: 30px;
    padding: 5px 20px;
    border: 3px solid $primaryPurple;
    font-family: Montserrat;
    font-weight: 300;
    color: $white;
    max-height: 40px;
    font-size: 20px;
    min-width: 12rem;
    width: 100%; }

  &-title {
    @include font(Montserrat, 400, 2rem, $primaryPurple);
    margin-left: 5vw;
    margin-right: 5vw; }

  &__referral-section {
    display: flex;
    margin: 1rem 0rem;
    gap: 1rem; }

  &--container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 75vh; }

  &_card {
    order: 0;
    // border: 1px solid
    border-radius: 30px;
    padding: 1.75rem 2rem 2.5rem 2rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    // background-color: $darkGray
    @include tablet-screen {
      display: none; }
    &-mobile {
      display: none;
      @include tablet-screen {
        order: 1;
        display: flex;
        background-color: $darkBgPurple;
        padding: 1rem;
        border-bottom: 0.5px solid $borderGray; } }

    @include tablet-screen {
      order: 2;
      border-radius: 15px;
      background-color: $darkBgPurple;
      border-color: $primaryPurple;
      justify-content: center; }

    &-title {
      display: flex;
      justify-content: center;
      padding: 0 0 2rem;
      @include tablet-screen {
        justify-self: end;
        display: none; }
      @include small-phone-screen {
        font-size: 1.2rem;
        display: none; }
      @include font(Montserrat, 400, 1.375rem, $mediumGray);
      margin-bottom: 0.625rem;
      @include smaller-phone-screen {
        font-size: 1.1rem; } }
    &-info {
      display: flex;
      flex-direction: column;

      &_item {
        display: flex;
        justify-content: space-between;
        margin-block: 0.2rem;
        min-width: 260px;
        max-width: 300px;
        align-self: center; }
      &_label {
        font-weight: 600; }
      &_value {
        max-width: 180px;
        word-wrap: break-word;
        text-align: end; }

      &-toolbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet-screen {
 } } }          // display: none
    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0 0 0.35rem 0;
      gap: 1rem;
      @include small-phone-screen {
        max-width: 14rem; }
      @include smaller-phone-screen {
        max-width: 12rem; }
      &:first-child {
        margin-right: 1rem; } }

    &-referrals {
      display: flex;
      flex-direction: column;
      max-width: 350px;
      &-total {
        @include flex-props-center;
        align-self: center;
        border: 3px solid $primaryPurple;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        margin-block: 20px;
        font-size: 2.625rem;
        font-weight: 400;
        color: $primaryPurple; }
      &-title {
        font-size: 1.375rem;
        font-weight: 400;
        text-align: center;
        &--bottom {
          color: $mediumGray; } }
      &-last {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem; }
      &-info {
        font-size: 0.875rem;
        font-weight: 400; } } } }

.node_table-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  background: $navigationBg;
  border-bottom: 0.5px solid $borderGray;
  height: 2.75rem;
  padding-left: 1rem;
  gap: 0.3125rem;
  @include tablet-screen {
    @include font(Montserrat, 600, 1rem, $white);
    gap: 0; }

  &--no-mobile {
    @include tablet-screen {
      display: none !important; } }

  &--no-small-mobile {
    @include small-phone-screen {
      display: none !important; } }

  &-unit {
    flex: 1;
    text-align: flex-start;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &:first-child {
      min-width: 10rem;
      @include tablet-screen {
        min-width: 0; }
      @include small-phone-screen {
        min-width: 6.5rem; }
      @media(max-width: 420px) {
        min-width: 6rem; } } }

  &-btn-filter {
    @include font(SFProDisplay, 400, 0.875rem, $white);
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -1rem;
    gap: 0.2rem;
    height: 100%;
    width: 100%;
    padding: 0.45rem;
    @include tablet-screen {
      @include font(SFProDisplayBold, 700, 1rem, $white); }
    img {
      width: 10px; }
    span {
      text-align: left; }
    &:focus {
      background-color:  $bgGray; }
    &:hover {
       background-color:  $borderGray; } } }

.node_table-list {
  max-height: 400px;
  overflow-y: auto;
  @include small-phone-screen {
    font-size: 0.8rem; }
  &--private {
    max-height: 300px; } }

.node_table-title {
  @include font(Montserrat, 400, 1.8rem, $white);
  margin-block: 2rem; }

.table-actions {
  justify-content: center;
  display: flex;
  padding-right: 2rem;
  min-width: 11.5rem;
  margin-right: 1rem;
  @include tablet-screen {
    display: none;
    min-width: 0; } }

.network__sections-profile-referral-code {
  display: inline-block;
  text-align: center;
  margin-top: 0.5rem;
  padding: 0.25rem 3rem;
  border-radius: 15px;
  background-color: $darkBgPurple;
  border: 0.05rem solid $primaryPurple;
  min-width: 100%;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: $white;
  word-break: break-all; }

.profile {
  &_input {
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out;
    opacity: 0;
    margin-bottom: 0.25rem;
    text-indent: 1rem;
    background-color: $bgDark;
    height: 0;
    border-radius: 5px;
    color: $white;
    margin-top: -2rem;

    &-editing {
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out;
      opacity: 1;
      margin-bottom: 0.25rem;
      text-indent: 1rem;
      background-color: $bgDark;
      height: 1.25rem;
      border-radius: 5px;
      color: $white; } }
  &_selector {
    width: fit-content; }

  &_text {
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
    opacity: 1;
    margin-bottom: -1rem;
    &-editing {
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
      opacity: 1;
      margin-bottom: 0.25rem; } }

  &_nodes {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 2rem;
    margin: 0 0 2rem 0;
    @include small-phone-screen {
      gap: 2rem;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0.5rem 2rem;
      margin: 0; } }

  &_content {
    display: flex;
    gap: 1rem;
    @include small-phone-screen {
      //margin-bottom: 5rem
      flex-direction: column; }
    &-item {
      font-weight: bold;
      &-title {
        &:first-child {
          margin-right: 0.75rem; }
        &-wrapper {
          display: flex;
          font-size: 0.875rem; } }
      &-title-icon {
        margin: 0 0 0 0.15rem;
        cursor: pointer;
        &-image {
          height: 1rem;
          margin: 0 0.5rem -0.25rem 0.5rem; } } }

    &-section {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      padding: 5px 0;
      @include small-phone-screen {
        padding: 5px; } } }
  &_avatar {
    height: 8rem;
    width: 8rem;
    background-color: $darkGray;
    border-radius: 50%;
    border: solid 3px $primaryPurple;
    overflow: hidden;
    display: flex;
    transition: ease-in-out 0.5s;

    &-clickable {
      transition: ease-in-out 0.5s;
      height: 8rem;
      width: 8rem;
      background-color: $bgDark;
      border-radius: 50%;
      border: solid 5px $bgDeepPurple;
      overflow: hidden;
      display: flex;
      cursor: pointer;

      &:first-child {
        filter: grayscale(100%) brightness(50%);
        transition: filter ease-in-out 0.5s; } } }

  &_buttons-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 30%;
    gap: 1rem; }
  &_near-subtitle {
    text-align: center; } }

.pofile_content-item-wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: column; }

.warning_notification {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $darkYellow;
  &:hover {
    span {
      text-decoration: underline;
      text-decoration-color: $gold; } }
  &.active {
      border-bottom: none; }




  img {
    margin-right: 0.5rem; }

  p, a {
    color: $gold; } }


@import "../../styles/helpers";

.body {
  &_management {
    @media (max-width: 692px) {
      max-width: 100%; } } }

.login {
  &_card {
    border-radius: 1.875rem;
    height: 20rem;
    width: 20rem;
    margin: 5rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
    &-title {
      @include font(Montserrat, 400, 1.25rem, $primaryPurple); }
    &-subtitle {
      @include font(Montserrat, 400, 1rem, $white); } } }

.vl {
  border-left: 0.15rem solid $mediumGray;
  height: auto;
  @include tablet-screen {
    display: none; } }

.management_node-search {
  @include flex-props-space-between;
  order: 2;
  margin-bottom: 2rem;
  @include tablet-screen {
    order: 1;
    align-items: flex-start;
    flex-direction: column; }

  &-title {
    display: inline-block;
    @include small-phone-screen {
      font-size: 1.5rem; } }

  &-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include tablet-screen {
      display: flex;
      margin-top: 1rem;
      width: 100%; }

    &-field {
      //max-width: 180px
      padding-left: 1rem;
      background-color: $bgDark;
      height: 2.9rem;
      border-radius: 10px 0 0 10px;
      color: $white;
      @include tablet-screen {
        width: 100%; }
      &::placeholder {
        color: $mediumGray; } }

    button {
      background-color: $bgDark;
      height: 2.9rem;
      border-radius: 0 10px 10px 0;
      padding-inline: 1.2rem; }

    img {
      padding-top: 0.2rem; } } }

.search_input {
  display: none;
  justify-content: flex-start;
  align-items: center;
  order: 3;
  @include tablet-screen {
    display: flex;
    align-self: flex-end;
    margin-bottom: 2rem; }


  input[type='text'] {
    max-width: 180px;
    padding-left: 1rem;
    background-color: $bgDark;
    height: 2.9rem;
    border-radius: 10px 0 0 10px;
    color: $white;
    &::placeholder {
      color: $mediumGray; } }

  button {
    background-color: $bgDark;
    height: 2.9rem;
    border-radius: 0 10px 10px 0;
    padding-inline: 1.2rem; }

  img {
    padding-top: 0.2rem; } }

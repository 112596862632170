.primary-button {
  background: transparent;
  border-radius: 45px;
  padding: 5px 30px;
  border: solid 3px $primaryPurple;
  display: block;
  text-align: center;
  margin: 0 auto 30px;
  @include font("Montserrat", 400, 1rem, $white);
  img {
    margin-left: 0.5rem; }
  @include phone-screen {
    font-size: 0.875rem; }
  &:hover {
    border-color: $primaryPurple;
    background-color: $bgPurple; } }

.primary-button--no-background {
  outline: 0;
  background: transparent;
  padding: 0.5rem 1.2rem;
  border-radius: 30px;
  filter: brightness(0.9);
  transition: filter 0.2s ease-in-out;
  border: 0.1rem solid $primaryPurple;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font("Montserrat", 400, 1rem, $white);
  img {
    margin-left: 0.5rem; }
  &:hover {
    filter: brightness(1.1); } }

.secondary-button {
  outline: 0;
  background: $primaryPurple;
  filter: brightness(0.9);
  transition: filter 0.2s ease-in-out;
  padding: 0.5rem 1.2rem;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font("Montserrat", 400, 1rem, $white);
  img {
    margin-left: 0.5rem; }
  &:hover {
    filter: brightness(1.1); } }

.modal-button {
  font-family: "Montserrat";
  cursor: pointer;
  border-radius: 25px;
  color: $primaryPurple;
  border: solid 2px $primaryPurple;
  padding: 5px 10px;
  background: transparent;
  transition: color, background 0.5s;
  font-size: 0.875rem;
  font-weight: 400;
  img {
    margin-left: 0.5rem; }
  @include smaller-phone-screen {
    font-size: 0.725rem; }
  &:hover {
    color: $white;
    text-shadow: 0 0 8px $bgPurple;
    background: $primaryPurple; } }

.image-button {
  background-color: transparent !important;
  img {
    background-color: transparent;
    border: none;
    width: 70%;
    @media (max-width: 978px) {
      width: 60%; } } }

@import "../../styles/helpers";

.network-testnet {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: 5vw;
    margin-right: 5vw;

    &__sections {
        max-width: 1000px;
        margin-bottom: 2rem;
        @media (max-width: 692px) {
            max-width: 100%; } }



    &__central {
        display: flex;
        justify-content: center;
        margin: 2rem 0; }

    &__join-hero {
        @include font(Montserrat, 300, 50px, $white);
        margin-bottom: 40px;
        white-space: nowrap;


        @media (max-width: 2048px) {
            transition: ease-in-out 1s;
            font-size: 42px;

            margin: 2rem 0;
            white-space: nowrap; }


        @media (max-width: 1280px) {
            transition: ease-in-out 1s;
            white-space: nowrap; }



        @media (max-width: 900px) {
            transition: ease-in-out 1s;
            font-size: 42px;
            white-space: nowrap; }




        @media (max-width: 768px) {
            font-size: 2.5rem;
            max-width: 90%;
            white-space: normal; }


        &-subtitle {

            margin: 2rem 0;

            @media (max-width: 768px) {
                font-size: 1.75rem;
                max-width: 90%; } } } }


.node {
    &__button {
        width: 20rem;
        height: 5rem;
        font-size: 2.5rem;
        border: 2px solid $white;
        border-radius: 12px;
        margin: 2rem 0;
        background-color: black;
        color: $white;

        @include phone-screen {
            width: auto; } }

    &__reward {
        display: flex;
        margin: 2rem 0; }
    &__prop-mobile {
        margin: 0rem 2rem 0rem 0rem; } }

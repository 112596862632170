@import "../../styles/helpers";

.cta-reward-program {
  &__section {
    padding: 0 5vw;
    width: 100vw;
    background: transparent;
    margin-bottom: 4rem;
    margin-top: 5rem;
    color: #fff;

    display: flex;
    justify-content: center;
    @include phone-screen {
      padding-inline: 5vw;
      margin-bottom: 2.5rem; } }
  &__background-image {
    position: absolute;
    width: 700px;
    top: 0;
    left: 0;
    @media (max-width: 1050px) {
      width: 600px; }
    @media (max-width: 1000px) {
      top: 0;
      left: 50%;
      transform: translate(-30%, 0); }
    @include small-phone-screen {
      width: 400px;
      transform: translate(-30%, 5%); } }
  &__hero-image {
    position: absolute;
    width: 450px;
    height: auto;
    left: 3rem;
    top: 2rem;
    @media (max-width: 1050px) {
      width: 300px; }
    @media (max-width: 1000px) {
      width: 250px;
      position: relative;
      align-self: center;
      left: auto;
      top: auto; }
    @include small-phone-screen {
      width: 180px; } }

  &__content {
    &-title {
      display: none;
      @include font(Montserrat, 300, 2.2rem, $white);
      text-align: flex-start;
      width: 100%;
      margin-top: 3rem;
      margin-bottom: 1rem;
      @media (max-width: 1000px) {
        display: block; } }
    &-text1 {
      @include font(Montserrat, 300, 2rem, $white);
      text-align: left;

      width: 100%;
      @media (max-width: 1000px) {
        display: none; } }

    &-text1-1 {
      @include font(Montserrat, 300, 2.5rem, $white);
      text-align: left;

      width: 100%;
      @media (max-width: 1000px) {
        display: none; } }

    &-text2 {
      @include font(Montserrat, 300, 2rem, $primaryPurple);

      text-align: left;
      margin-block: 2rem;
      @media (max-width: 1000px) {
        align-self: center; }
      @include smaller-phone-screen {
        text-align: center; } }

    &-text3 {
      @include font(Montserrat, 300, 2rem, $white);


      margin-bottom: 2rem;
      text-align: left;
      @media (max-width: 1000px) {
        margin-bottom: 2rem;
        align-self: center;
        text-align: center;
        margin-left: 0; } }

    &-text4 {
      @include font(Montserrat, 300, 1.5rem, $white);

      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
      text-align: left;
      @media (max-width: 1000px) {
        margin-bottom: 2rem;
        align-self: center;
        text-align: center;
        margin-left: 0; } }

    &-liImage {
      li {
            list-style: none;
            padding: 10px 35px;
            background-image: url("../../assets/images/arrow-64.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 30px;
            text-align: left; } }

    &-container {
      display: flex;

      gap: 2rem;
      height: 100%;
      width: 1200px;
      @media (max-width: 1000px) {
        flex-direction: column;
        gap: 0; }
      a {
        @include phone-screen {
          font-size: 1.5rem; } }
      p {
        line-height: 2.3rem;
        @include phone-screen {
          @include font(Montserrat, 300, 1.5rem, $white); } } }
    &-images {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 420px;
      align-self: center;
      overflow: hidden;
      @media (max-width: 1050px) {
        min-height: 360px; }
      @include small-phone-screen {
        min-height: 280px; } }
    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-top: 4rem;
      width: 100%;


      @media (max-width: 1000px) {
        margin-top: -0.5rem; } }
    &-link {

      margin-bottom: 4rem;
      background: $darkPurple;
      padding: 0.5rem 2rem;
      @include font(Montserrat, 300, 1.375rem, $white);
      border-radius: 30px;
      filter: brightness(1);
      transition: filter 0.2s ease-in-out;
      &:hover {
        filter: brightness(1.2); }
      @media (max-width: 1000px) {
        align-self: center;
        font-size: 1rem !important; } } } }


@import "../../styles/helpers.sass";

.wallet_connection {
  max-width: 90vw;
  &-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 7rem;
    gap: 6.2rem;
    @media (max-width: 978px) {
      @include flex-props-column-center;
      gap: 6rem; } }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    transition: all .5s ease;
    gap: 1.5rem;

    &-title {
      @include font(Montserrat, 400, 1.75rem, $white);
      line-height: 2.5rem;
      @include small-phone-screen {
        font-size: 1.5rem;
        line-height: 1.8rem; }
      &-login {
        margin-top: -0.5rem;
        @include font(Montserrat, 400, 1rem, $mediumGray); } }

    &-button {
      &:last-child {
        margin-top: 2.5rem; } } }

  &-tutorial-text {
    @include font(Montserrat, 400, 1rem, $white);
    line-height: 1.3rem;
    max-width: 85%;
    margin-bottom: 0.8rem;
    text-align: left;
    @media (max-width: 500px) {
      font-size: 0.9rem;
      line-height: 1.2rem; } }

  &-list {
    list-style: none;

    &-item {
      @include flex-props-space-between;
      width: 100%;
      margin-bottom: 0.9rem; }

    &-item-image {
      width: auto;
      height: 1.875rem; }

    &-item-text {
      width: 80%; } }

  &-bottom-text {
    @include font(Montserrat, 400, 1.75rem, $white);
    text-align: center;
    margin-top: 3rem;
    @media (max-width: 1200px) {
      font-size: 1.5rem;
      line-height: 1.8rem; } } }

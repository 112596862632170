@import "../../styles/_helpers.sass";

.news__form {
    display: flex;
    color: #fff;
    flex-direction: column;
    paddign: 2rem;
    background-color: #2d2d2d;
    width: 90vw;
    max-width: 1000px;
    margin: 0 2rem;
    @include phone-screen {
        width: 85vw; }
    &-wrapper {
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 2rem;
        padding: 2rem 0; }
    &-item {
        display: flex;
        gap: 0.25rem;
        flex-direction: column;
        width: 100%;
        color: #FFB432;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        max-width: 600px;
        input, label {
            height: 30px;
            padding-left: 1rem; }


        textarea {
            height: 120px;
            padding-left: 1rem;
            padding-top: 1rem;
            resize: vertical; } }

    &-title {
        margin: 2rem 0 0 2rem; } }

.news__page {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center; }

.form__button-wrapper {
    width: fit-content;
    margin-top: 1rem; }

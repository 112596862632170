@mixin button($size, $color: none) {
  font-size: $size;
  @if $color != none {
    background-color: $color; } }

@mixin font($family, $weight, $size, $color) {
  font-family: $family, sans-serif;
  font-weight: $weight;
  font-size: $size;
  color: $color; }

@mixin background-props {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@mixin flex-props-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; }

@mixin flex-props-space-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row; }

@mixin flex-props-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; }

@mixin flex-props-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row; }

@mixin flex-props-column-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

@mixin flex-props-column-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column; }

@mixin flex-props-row-space-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row; }

@mixin flex-props-column-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column; }

@mixin menu-size__max {
  @media (max-width: 900px) {
    @content; } }

@mixin tablet-screen {
  @media (max-width: 840px) {
    @content; } }

@mixin phone-screen {
  @media (max-width: 768px) {
    @content; } }

@mixin small-phone-screen {
  @media (max-width: 500px) {
    @content; } }

@mixin smaller-phone-screen {
  @media (max-width: 360px) {
    @content; } }


@mixin menu-size__max {
  @media (max-width: 1040px) {
    @content; } }


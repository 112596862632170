@import "../../../styles/_helpers.sass";

.network_download {
    @include font(Montserrat, 400, 0.875rem, $white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    margin: 6rem 1rem 1rem 2rem;
    @media (max-width: 692px) {
        margin: 0;
        margin-top: 3rem;
        margin-left: 5vw;
        margin-right: 5vw; } }

.network__sections {
    max-width: 1000px;
    margin-bottom: 2rem;
    @media (max-width: 692px) {
        max-width: 100%;
        margin-top: 0.5rem; } }

.logo {

    &__os {
        height: 5rem;
        width: 5rem;
        background-color: $primaryPurple;
        margin: 1rem;
        border-radius: 50%;
        transition: box-shadow 0.5s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 10px 6px $primaryPurple; }

        &-active {
            box-shadow: 0px 0px 10px 6px $primaryPurple; } }

    @media (max-width: 375px) {
        display: flex;
        flex-direction: column;
        justify-content: center; }

    &__button {
        background-color: black;
        color: $white; } }

.os {
    &__container {
        display: flex;
        flex-direction: row; } }

.downloads {
    &__central {
        display: flex;
        justify-content: center;
 }        // margin: 2rem 0
    &__wrapper {
        @include flex-props-row-space-around;
        gap: 1rem;

        @include phone-screen {
            flex-direction: column;
            justify-content: center;
            margin: 0 5vw; } }
    &__section__wrapper {
        @include phone-screen {
            margin: 0 5vw; } } }

.downloads {
    &__requirements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // margin: 2rem 0
        &-p {
            margin: 0.5rem 0; }

        &-subtext {
            margin: 2rem 0;
            font-weight: 600; }

        & div {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out; }

        &--expanded div {
            opacity: 1;
            // padding-top: 1rem
            max-height: 1200px; } } } // Adjust this value based on your content size.

.bold {
    font-weight: 600; }

.image {
    &__docker {
        width: 85%;
        height: 85%;
        margin: 2rem 0; } }

.indented {
    margin-left: 2rem;
    @include phone-screen {
        margin-left: 0; } }

.command-line {
    display: flex;
    gap: 5rem; }

.command-item {
    white-space: nowrap;
    min-width: 14.75rem; }

.darkmode__icon-button {
    min-width: 16px;
    min-height: 16px;
    background-color: transparent; }

.darkmode__icon-item {
 }    //filter: invert(100%)

.darkmode__icon-item:hover {
    filter: invert(30%) sepia(15%) saturate(275%) hue-rotate(255deg); }

.darkmode__wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem; }

.card--instructions {
 }    // margin-top: -2rem
.card--instructions-wrapper {
    // margin-top: 2rem
 }    //

.instructions__option {
    @include circle__display; }

.options__circle--wrapper {
    @include flex-props-column-center;

    @media (max-width: 768px) {
        display: flex; } }

.downloads__wrapper {
    @include flex-props-row-space-around;
    gap: 1rem;

    @include phone-screen {
        margin-left: 0;
        gap: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center; } }

.line__spacing {
    margin-bottom: 0.35rem; }

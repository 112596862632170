@import "../../styles/helpers.sass";

.headers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

.header {
  height: 65px;
  @include flex-props-center;
  padding: 0 65px;
  background: $navigationBg;
  backdrop-filter: blur(10px);
  position: relative;
  border-bottom: 0.5px solid $borderGray;
  @include menu-size__max {
    border-bottom: 3px solid $darkGrayDetails; }

  @media (min-width: 800px) {
    padding: 30px 50px; }

  @media (min-width: 1200px) {
    padding: 30px 80px; }

  &-logo {
    width: 147px;
    height: auto;
    position: absolute;
    left: 30px;
    top: 17px;
    filter: drop-shadow(0 0 0.75rem black);

    @media (min-width: 1200px) {
      left: 80px; }

    @include menu-size__max {
      width: 120px;
      left: 20px; }

    @media (max-width: 500px) {
      width: 100px;
      top: 20px; }

    @media (max-width: 360px) {
      width: 90px;
      top: 22px;
      left: 15px; } }

  &-nav {
    @include flex-props-center;
    text-shadow: 1px 1px 3px black;
    max-width: 900px;
    width: 100%;
    display: flex;

    a:visited {
      color: $white; }


    &-signout {
      background: transparent;
      border: 3px solid transparent;
      border-radius: 30px;
      padding: 5px 20px;
      font-family: Montserrat;
      font-weight: 300;
      color: $white;
      max-height: 40px;
      font-size: 20px;
      text-shadow: 1px 1px 3px black;
      &:hover {
        border: 3px solid $primaryPurple; }
      @media (max-width: 1260px) {
        font-size: 18px;
        margin-right: -0.5rem; } }

    &-link {
      margin: 0 15px;
      padding: 10px 10px 3px;
      border-bottom: 3px solid transparent;
      transition: .3s ease border-bottom-color;
      @include font(Montserrat, 300, 1.1rem, $white);
      @include menu-size__max {
        font-size: 20px;
        padding: 10px 5px 3px; }

      &-align-right {
        display: flex;
        gap: 2.5rem;
        font-size: 1rem;

        @media (min-width: 1200px) {
          right: 80px; } }

      &-active {
        border-bottom-color: $primaryPurple; } } }

  &-dropdown {
    position: relative;
    width: 140px;
    @include flex-props-center;
    justify-content: flex-end;

    @media (min-width: 800px) {
      width: 140px; }

    @media (min-width: 1200px) {
      width: 251px; }

    &-icon {
      opacity: 1;
      transition: .3s ease opacity;
      cursor: pointer;

      svg {
        width: 40px;
        height: 40px; }

      &-disabled {
        animation: .3s linear dropdown-icon;
        opacity: 0;
        z-index: -3; } }

    &-content {
      position: absolute;
      opacity: 0;
      z-index: -1;
      transition: opacity .3s ease .3s;

      &-active {
        z-index: 1;
        opacity: 1; }

      &-sign-in {
        background-color: $white;
        color: $black;
        padding: 10px 25px;
        border-radius: 20px;
        @include font(Montserrat, 400, 21px, $black); }

      &-account {
        width: 250px;
        height: 70px;
        background-color: $white;
        border-radius: 30px;
        position: relative;
        cursor: pointer;
        @include flex-props-center;

        &-active {
          border-radius: 30px 30px 10px 10px; }

        &-icon {
          margin-right: 20px; }

        &-address {
          @include font(Montserrat, 400, 21px, $black); }

        &-logout {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 50%;
          left: 0;
          bottom: -25px;
          background-color: $primaryPurple;
          transition: .5s ease;
          @include font(Montserrat, 400, 21px, $white);
          border-radius: 0 0 30px 30px;

          &-active {
            opacity: 1; } } } } } }

.header-mobile {
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 20;

  &-dummy {
    height: 65px; }

  &-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    display: none;
    @include menu-size__max {
      @include flex-props-column-center;
      z-index: 11; } }

  &-logo {
    width: 120px;
    height: auto; }

  &-subheader {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    height: 30px;
    @include flex-props-space-between;
    padding: 0 15px;
    // background: rgba(175, 175, 175, 0.3)
    background: $navigationBg;
    backdrop-filter: blur(10px);

    &-current-page {
      @include font(Montserrat, 400, 16px, $primaryPurple); } }

  &-overlay {
    z-index: 9;
    position: fixed;
    inset: 0;
    background: transparent; }

  &-burger {
    width: 100%;
    position: absolute;
    top: 65px;
    z-index: 10;
    background: $navigationBg;
    backdrop-filter: blur(10px);
    height: fit-content;
    &-open {
      width: 28px;
      height: 28px;
      &-element {
        transition: fill .3s ease; } }

    &-items {
      @include flex-props-column-space-evenly;
      gap: 1rem;
      align-items: flex-end;
      padding-right: 18px;
      height: 100%;
      width: 100%;
      margin-top: 0.5rem; }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.5rem;
      &-link, &-sublink {
        @include font(Montserrat, 300, 20px, $white);
        margin-bottom: 5px;
        padding: 2px 5px;

        &-active {
          color: $primaryPurple;
          font-weight: 400; } }

      &-sublink {
        color: $white; }

      &-signout {
        background: transparent; } } } }

.header-mobile-burger-hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  margin-right: 2rem; }

.burger-expanded {
  max-height: 400px;
  transition: max-height 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  margin-right: 2rem; }

@keyframes dropdown-icon {
  0% {
    z-index: 1;
    opacity: 1; }

  50% {
    opacity: 0; }

  100% {
    z-index: -1; } }

.nav-component {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  min-width: fit-content;

  @include menu-size__max {
    display: none; }

  &-right {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-right: 1rem;
    position: absolute;
    right: 0;
    min-width: fit-content; } }

.active {
  color: $white;
  border-bottom: 2px solid $primaryPurple; }
